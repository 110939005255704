import { type MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'
import { dashboardConfig } from '#app/utils/config/dashboard.ts'
import { siteConfig } from '#app/utils/config/site.ts'

export const meta: MetaFunction = () => [{ title: 'PCDH19' }]

export default function Index() {
	return (
		<>
			<section className="space-y-6 pb-8 pt-6 md:pb-12 md:pt-10 lg:py-32">
				<div className="container flex max-w-[64rem] flex-col items-center gap-4 text-center">
					<Link
						to={siteConfig.links.twitter}
						className="rounded bg-fuchsia-200 px-4 py-1.5 text-sm font-medium"
						target="_blank"
					>
						Siguenos en Twitter
					</Link>
					<h1 className="font-heading  text-xl font-extrabold sm:text-5xl md:text-3xl lg:text-5xl">
						Asociación de pacientes PCDH19
					</h1>
					<p className="max-w-[42rem] leading-normal text-muted-foreground sm:text-xl sm:leading-8">
						Los pacientes afectados por la mutación en el gen PCDH19 y sus
						familiares nos hemos unido para mejorara la calidad de vida de estos
						afectados, ¿nos ayudas?
					</p>
				</div>
			</section>
			<section
				id="features"
				className="container space-y-6 bg-fuchsia-100 py-8 dark:bg-transparent md:py-12 lg:py-24"
			>
				<div className="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center">
					<h2 className="font-heading  text-xl font-extrabold sm:text-4xl md:text-2xl lg:text-4xl">
						Conócenos mejor
					</h2>
					<p className="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
						Somos una pequeña Asociación de familiares y pacientes afectados por
						la mutación en el gen PCDH19. Esta mutación, además de crisis
						epilépticas en cadena, supone en la mayoría de los casos la
						aparición de otras muchas alteraciones y dificultades en las
						personas que la sufren. Se trata de una enfermedad rara que, además,
						es poco conocida y está infradiagnosticada.
					</p>
				</div>
				<div className="mx-auto grid justify-center gap-4 sm:grid-cols-2 md:max-w-[64rem] md:grid-cols-3">
					{dashboardConfig.mainNav.map(item => (
						<div
							key={item.title}
							className="relative overflow-hidden rounded-lg border bg-background p-2"
						>
							<div className="flex h-[180px] flex-col justify-between rounded-md p-6">
								<div className="space-y-2">
									<h3 className="font-bold">{item.title}</h3>
									<p className="text-sm text-muted-foreground">
										{item.description}
									</p>
									<p className="mt-8">
										<Link
											className="mt-8 text-sm font-bold text-fuchsia-800 hover:text-fuchsia-500"
											to={item.href}
										>
											{' '}
											Saber más
										</Link>
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</section>
			<section id="open-source" className="container py-8 md:py-12 lg:py-24">
				<div className="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center">
					<h2 className="font-heading  text-xl font-extrabold sm:text-4xl md:text-2xl lg:text-4xl">
						Contacta con nosotros
					</h2>
					<p className="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
						{/* Puedes escribir a la siguiente dirección para contactarnos:
						xxxxxxxx@pcdh19.com */}
						Diego Jimenez Pavón: (+34) 657 274 067
					</p>
				</div>
			</section>
		</>
	)
}
